#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  /* color: #f6faf6; */
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-pagination {
  bottom: 22px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background-color: #f6faf6 !important;
  /* padding-top: 200px; */
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 250px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullet-active {
  background-color: #21d57c !important;
}
